export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^([0-9])?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        var intlCode = match[1] ? `+${match[1]} ` : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            '',
        );
    }
    return null;
};
