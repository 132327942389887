import {
    LOGIN,
    LOGIN_FAILED,
    LOGOUT,
    UPDATE_USER,
} from '../constants/auth-action-types';

const userData = JSON.parse(localStorage.getItem('userData'));
const initialState = userData || {
    loggedIn: false,
    accessToken: false,
    loggedUser: {},
    errorMessage: false,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            let newState = {
                ...state,
                errorMessage: false,
                loggedIn: true,
                accessToken: action.payload.accessToken,
                loggedUser: action.payload.user,
            };
            localStorage.setItem('userData', JSON.stringify(newState));
            return newState;
        case LOGIN_FAILED:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case LOGOUT:
            return {
                loggedIn: false,
                accessToken: false,
                loggedUser: {},
                errorMessage: false,
            };
        case UPDATE_USER:
            let updatedState = {
                ...state,
                loggedUser: {
                    ...state.loggedUser,
                    ...action.payload,
                },
            };
            localStorage.setItem('userData', JSON.stringify(updatedState));
            return updatedState;
        default:
            return state;
    }
};

export default authReducer;
