import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

// ** Redux Imports
import { Provider } from 'react-redux';
import store from './redux/store';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';

import { ToastContainer } from 'react-toastify';

import Loading from './components/Loading';

// import { Layout } from './utility/context/Layout';

//import reportWebVitals from "./reportWebVitals";

// ** React Toastify
import './assets/styles/toastify.scss';

import './assets/styles/style.scss';

import { clarity } from 'react-microsoft-clarity';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

if (process.env.REACT_APP_CLARITY_MODE === 'production') {
    clarity.init(process.env.REACT_APP_CLARITY_ID);
}

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Loading />}>
            <AbilityContext.Provider value={ability}>
                <BrowserRouter>
                    {/* <Layout> */}
                    <LazyApp />
                    <ToastContainer newestOnTop />
                    {/* </Layout> */}
                </BrowserRouter>
            </AbilityContext.Provider>
        </Suspense>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
