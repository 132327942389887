import {
    MATTER_DETAIL_CHANGE_TAB,
    MATTER_DETAIL_FETCH_PAYMENTS,
    MATTER_DETAIL_FETCH_PAYMENTS_DETAILS,
    MATTER_DETAIL_INIT_SEARCH_PARAMS,
    MATTER_DETAIL_RESET_STATE,
    MATTER_DETAIL_SHOW_LOADING,
    MATTER_DETAIL_TOGGLE_UPDATE_MODAL,
} from '../constants/matterDetailActionTypes';

const initialState = {
    activeTab: 1,
    matterPayments: [],
    pageLength: 0,
    loading: false,
    searchParams: {
        referralId: null,
        page: 0,
        pageSize: 10,
    },
    statusUpdateModal: {
        isUpdateModalOpen: false,
        initialData: null,
    },
    paymentDetails: null,
};

const matterDetailReducer = (state = initialState, action) => {
    switch (action.type) {
        case MATTER_DETAIL_CHANGE_TAB:
            return {
                ...state,
                activeTab: action.payload,
            };
        case MATTER_DETAIL_SHOW_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case MATTER_DETAIL_FETCH_PAYMENTS:
            return {
                ...state,
                ...action.payload,
            };
        case MATTER_DETAIL_TOGGLE_UPDATE_MODAL:
            return {
                ...state,
                statusUpdateModal: {
                    isUpdateModalOpen: action.payload.isUpdateModalOpen,
                    initialData: action.payload.initialData,
                },
            };
        case MATTER_DETAIL_INIT_SEARCH_PARAMS:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    ...action.payload,
                },
            };
        case MATTER_DETAIL_FETCH_PAYMENTS_DETAILS:
            return {
                ...state,
                paymentDetails: action.payload,
            };
        case MATTER_DETAIL_RESET_STATE:
            return initialState;
        default:
            return state;
    }
};

export default matterDetailReducer;
