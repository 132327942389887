import { combineReducers } from 'redux';
import authReducer from './authReducer';
import layoutReducer from './layoutReducer';
import matterDetailReducer from './matterDetailReducer';

export default combineReducers({
    auth: authReducer,
    layout: layoutReducer,
    matterDetail: matterDetailReducer,
});
