import {
    HANDLE_MENU_COLLAPSED,
    HANDLE_REFERRAL_FLAGGED,
    HANDLE_SELECT_REFERRAL,
    HANDLE_BILLINGS_COUNT,
} from '../constants/layout-action-types';

const layoutData = JSON.parse(localStorage.getItem('layoutData'));
const initialState = layoutData || {
    menuCollapsed: false,
    flagged: {
        Declined: false,
    },
    selectedMatter: false,
    newTasksCount: 0,
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_MENU_COLLAPSED:
            localStorage.setItem(
                'layoutData',
                JSON.stringify({
                    ...state,
                    menuCollapsed: action.payload,
                }),
            );
            return {
                ...state,
                menuCollapsed: action.payload,
            };
        case HANDLE_REFERRAL_FLAGGED:
            return {
                ...state,
                flagged: {
                    ...state.flagged,
                    [action.payload.key]: action.payload.value,
                },
            };
        case HANDLE_SELECT_REFERRAL:
            let newState = {
                ...state,
                selectedMatter: action.payload,
            };
            localStorage.setItem('layoutData', JSON.stringify(newState));
            return newState;
        case HANDLE_BILLINGS_COUNT:
            return {
                ...state,
                billingsCount: action.payload,
            };
        default:
            return state;
    }
};

export default layoutReducer;
