export const MATTER_DETAIL_CHANGE_TAB = 'MATTER_DETAIL_CHANGE_TAB';
export const MATTER_DETAIL_FETCH_PAYMENTS = 'MATTER_DETAIL_FETCH_PAYMENTS';
export const MATTER_DETAIL_SHOW_LOADING = 'MATTER_DETAIL_SHOW_LOADING';
export const MATTER_DETAIL_TOGGLE_UPDATE_MODAL =
    'MATTER_DETAIL_TOGGLE_UPDATE_MODAL';
export const MATTER_DETAIL_INIT_SEARCH_PARAMS =
    'MATTER_DETAIL_INIT_SEARCH_PARAMS';
export const MATTER_DETAIL_RESET_STATE = 'MATTER_DETAIL_RESET_STATE';
export const MATTER_DETAIL_FETCH_PAYMENTS_DETAILS =
    'MATTER_DETAIL_FETCH_PAYMENTS_DETAILS';
